function moneyFormat(number) {
	var decimals = 0,
		dec_point = '.',
		thousands_sep = ' ';

	number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
	var n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
		dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
		s = '',
		toFixedFix = function(n, prec) {
			var k = Math.pow(10, prec);
			return '' + (Math.round(n * k) / k)
				.toFixed(prec);
		};

	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array(prec - s[1].length + 1).join('0');
	}
	return s.join(dec);

}

$(function() {
	$('.file-container input').change(function (e) {
		//console.log($(this).val().replace(/([^\\]*\\)*/,''));
		$(this).closest('.file-container').find('.file-message').html($(this).val().replace(/([^\\]*\\)*/,''));

		$('.file-container input').each(function (key, element) {
			//console.log(element);
			if($(element).val()) {
				$('#file-container-' + (key + 2)).removeClass('hidden');
			}
		});
	});

	$(".fancybox").fancybox({
		'transitionIn'     :     'elastic',
		'transitionOut'     :     'elastic',
		'speedIn'          :     100,
		'speedOut'          :     100,
		'changeFade'     :     150,
		'changeSpeed'     :     150,
		'overlayOpacity':     0.7,
		'overlayColor'  :     '#000',
		'titlePosition'     :     'over',
		'padding'          :     2
	});
});

$('a[data-scrollto="1"]').click(function(e){
	var fullhref = $(this).prop('href');
	var href = $(this).attr('href');
	if(fullhref.indexOf(window.location.href) > -1) {

	} else {
		return;
	}

	e.preventDefault();
	var element = $(href.replace('/',''));
	var top = 0;
	if(element.length > 0) {
		top = element.offset().top;
	}

	$('html, body').animate({
		scrollTop: top
	}, 500);
});