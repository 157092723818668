$(function () {
	$('.navbar-nav-toggle').click(function() {
		var open = (!$(this).closest('li').hasClass('open'));
		$('.navbar-nav li.open').removeClass('open');
		$(this).parentsUntil($(this).closest('.navbar-nav'), 'li').addClass('open');
		if (!open) {
			$(this).closest('li').removeClass('open');
		}

		return false;
	});

	$('.nav-tabs.nav-tabs-collapsible').on('show.bs.tab', function (e) {
		var $target = $(e.target);
		$($target.data('toggle-target')).text($target.text());
		$target.closest('.navbar-collapse').collapse('hide');
	});

});
